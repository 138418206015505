import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/nftx-developers.github.io/nftx-developers.github.io/packages/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const PageDescription = makeShortcode("PageDescription");
const MediumPosts = makeShortcode("MediumPosts");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`Using a Gatsby plugin, this component automatically builds three `}<inlineCode parentName="p">{`ArticleCards`}</inlineCode>{`
populated with the most recent Medium posts at build time.`}</p>
    </PageDescription>
    <h2>{`Setup`}</h2>
    <p>{`To update the source account for this component, pass in a `}<inlineCode parentName="p">{`mediumAccount`}</inlineCode>{` to
your theme options in `}<inlineCode parentName="p">{`gatsby-config.js`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`plugins: [
  {
    resolve: 'gatsby-theme-carbon',
    options: {
      mediumAccount: 'carbondesign',
    },
  },
];
`}</code></pre>
    <h2>{`Example`}</h2>
    <MediumPosts color="dark" postLimit={3} mdxType="MediumPosts" />
    <h2>{`Code`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-mdx",
        "metastring": "path=components/MediumPosts/MediumPosts.js src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/MediumPosts",
        "path": "components/MediumPosts/MediumPosts.js",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/MediumPosts"
      }}>{`<MediumPosts color="dark" postLimit={3} />
`}</code></pre>
    <h3>{`Props`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`propType`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`required`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Sets the card for the correct color theme, default is `}<inlineCode parentName="td">{`light`}</inlineCode>{`, options are `}<inlineCode parentName="td">{`light`}</inlineCode>{` and `}<inlineCode parentName="td">{`dark`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`postLimit`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`3`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Sets the number of posts pulled from Medium, default is `}<inlineCode parentName="td">{`3`}</inlineCode>{`, maximum is `}<inlineCode parentName="td">{`10`}</inlineCode>{`.`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      